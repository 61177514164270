import React, { FC, memo } from 'react'
import NewNavigationListItem, {
    NewNavigationListItemProps,
} from '@sport1/news-styleguide/NewNavigationListItem'
import Link, { LinkProps } from 'next/link'

type WrappedNavigationListItemProps = NewNavigationListItemProps & LinkProps

const WrappedNavigationListItem: FC<WrappedNavigationListItemProps> = ({ href, ...rest }) => {
    if (href)
        return (
            <Link href={href} passHref legacyBehavior>
                <NewNavigationListItem {...rest} />
            </Link>
        )

    return <NewNavigationListItem {...rest} />
}

export default memo(WrappedNavigationListItem)
