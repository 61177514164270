import React, { FC, useCallback } from 'react'
import NonFlexingContainer from '@sport1/news-styleguide/NonFlexingContainer'
import { LabelProps, MoreListItemProps, TagProps } from '@sport1/types/web'
import { useRouter } from 'next/router'
import WrappedNavigationListItem from '../WrappedNavigationListItem'
import nofollowExternal from '@/utils/url/nofollowExternal'

type Props = {
    items: MoreListItemProps[]
    onTagPress?: (tag: TagProps | LabelProps | MoreListItemProps) => void
}

const MoreList: FC<Props> = ({ items, onTagPress }) => {
    const { asPath } = useRouter()

    const getHref = useCallback(({ urlType, url }: MoreListItemProps): string => {
        if (url && (urlType === 'WEB_VIEW' || urlType === 'EXTERNAL' || urlType === 'INTERNAL')) {
            return url
        }
        return ''
    }, [])

    return (
        <NonFlexingContainer spaceBottom="small">
            {items.map(item => {
                const href = getHref(item)
                // TODO: https://sport1digital.atlassian.net/browse/ML-6214
                return (
                    <WrappedNavigationListItem
                        key={`${item.title}`}
                        testID={`more-list-navigation-item-${item.title}`}
                        title={item.title}
                        leadingType="image"
                        leading={item.iconUrl}
                        backgroundColor={href === asPath ? 'onyx-opacity-12' : 'pearl'}
                        href={href}
                        trailingIcon="scroll-right"
                        trailingIconColor="keshi-3"
                        onPress={onTagPress ? () => onTagPress(item) : undefined}
                        webRel={nofollowExternal}
                    />
                )
            })}
        </NonFlexingContainer>
    )
}

export default MoreList
